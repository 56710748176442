<template>
	<div>
		<div
			v-if="!isLoaded"
			class="preloader"
		/>
		<div
			v-else
			class="content"
		>
			<div
				class="profile-block"
				:class="{ 'profile-rekrut': isRecruiter }"
			>
				<div class="form">
					<div class="profile-section">
						<div class="profile-info">
							<div class="grid">
								<div class="box">
									<div class="profile-avatar-block">
										<h3 class="block-title">
											Основная информация
										</h3>
										<div class="profile-avatar-wrap">
											<label
												class="profile-avatar"
												:style="srcAvatar | bgrDynamicImageCover"
											>
												<input
													ref="avatar"
													type="file"
													@change="changeAvatar"
												>
											</label>
											<a
												v-if="isRemoveAvatar"
												href="#"
												class="link-dashed"
												@click.stop.prevent="removeAvatar"
											>Удалить
												фото</a>
										</div>
										<button
											class="button-hollow lg"
											@click.stop.prevent="modalOpen('changePass')"
										>
											Сменить пароль
										</button>
										<button
											v-if="!isRecruiter"
											style="margin-top: 15px"
											class="button-hollow lg"
											@click.stop.prevent="modalOpen('SendPromoCode')"
										>
											Ввести промокод
										</button>
									</div>
								</div>
								<div class="box">
									<div class="profile-section">
										<div class="profile-fields">
											<h3 class="block-title">
												Основная информация
											</h3>
											<div
												v-if="!isRecruiter"
												class="user-id"
											>
												<span>Мой id: {{ userInfo.id }}</span>
											</div>
											<div
												v-if="isRecruiter"
												class="profile-status"
											>
												Рекрутер
											</div>
											<div
												class="cells"
												style="display: block"
											>
												<div class="cell">
													<div class="field-wrap">
														<label class="field-label">
															<input
																v-model.trim="$v.userInfo.last_name.$model"
																type="text"
																class="field text-style"
																placeholder="Фамилия"
															>
															<div class="label">Фамилия</div>
															<span
																v-if="$v.userInfo.last_name.$error"
																class="help-block form-error"
															>
																{{
																	!$v.userInfo.last_name.required ? 'Это обязательное поле' :
																	!$v.userInfo.last_name.alphaSpace ? 'Только буквы, пробел и дефис' : 'Введите корректную фамилию'
																}}
															</span>
														</label>
													</div>
												</div>
												<div class="cell">
													<div class="field-wrap">
														<label class="field-label">
															<input
																v-model.trim="$v.userInfo.first_name.$model"
																type="text"
																class="field text-style"
																placeholder="Имя"
															>
															<div class="label">Имя</div>
															<span
																v-if="$v.userInfo.first_name.$error"
																class="help-block form-error"
															>
																{{
																	!$v.userInfo.first_name.required ? 'Это обязательное поле' :
																	!$v.userInfo.first_name.maxLength ? `Не более 30 символов` : 'Ошибка поля' ||
																		!$v.userInfo.first_name.alphaSpace ? 'Только буквы, пробел и дефис' : 'Введите корректное имя'
																}}
															</span>
														</label>
													</div>
												</div>
												<div class="cell">
													<div class="field-wrap">
														<label class="field-label">
															<input
																v-model.trim="userInfo.middle_name"
																type="text"
																class="field text-style"
																placeholder="Отчество"
															>
															<div class="label">Отчество</div>
														</label>
													</div>
												</div>
												<div class="cell">
													<div class="field-wrap">
														<label class="field-label">
															<input
																v-model.trim="userInfo.birthday"
																v-mask="'##.##.####'"
																type="text"
																class="field"
																placeholder="Дата рождения"
															>
															<div class="label">Дата рождения</div>
															<span
																v-if="$v.userInfo.birthday.$error"
																class="help-block form-error"
															>
																{{
																	!$v.userInfo.birthday.required ? 'Это обязательное поле' : !$v.userInfo.birthday.Birthday ? 'Формат даты ДД.ММ.ГГГГ' : 'Ошибка поля'
																}}
															</span>
														</label>
													</div>
												</div>
												<div class="cell">
													<div class="field-wrap">
														<label class="field-label">
															<input
																v-model="userInfo.phone"
																v-mask="'+7(###)###-##-##'"
																type="text"
																class="field"
																placeholder="Телефон"
															>
															<div class="label">Телефон</div>
															<span
																v-if="$v.userInfo.phone.$error"
																class="help-block form-error"
															>
																{{
																	!$v.userInfo.phone.required ? 'Это обязательное поле' : !$v.userInfo.phone.Phone ? 'Введите корректный номер телефона' : 'Ошибка поля'
																}}
															</span>
														</label>
													</div>
												</div>
												<div class="cell">
													<div class="field-wrap">
														<label class="field-label">
															<input
																v-model.trim="userInfo.email"
																type="text"
																class="field"
																:isError="$v.userInfo.email.$error"
																placeholder="Email"
															>
															<div class="label">Email</div>
															<span
																v-if="$v.userInfo.email.$error"
																class="help-block form-error"
															>
																{{
																	!$v.userInfo.email.required ? 'Это обязательное поле' :
																	!$v.userInfo.email.email ? 'Введите корректный e-mail (на английском)' :
																	!$v.userInfo.email.maxLength ? `Не более ${$v.userInfo.email.maxLength.max} символов` : 'Ошибка поля'
																}}
															</span>
														</label>
													</div>
												</div>
												<div
													v-if="!isRecruiter"
													class="cell"
												>
													<div class="field-wrap">
														<label class="field-label">
															<c-select
																:key="chosenRegion"
																:name="'Регион:'"
																autosearch-region
																:default-data="userInfo.region"
																:is-error="$v.userInfo.region.$dirty && !$v.userInfo.region.required"
																@selected="selectRegion"
																@defaultCity="chooseCity"
															/>
														</label>
													</div>
												</div>
												<div class="cell">
													<div class="field-wrap">
														<label class="field-label">
															<c-select
																:key="chosenCity"
																:name="'Город:'"
																autosearch-city
																:is-clear="isCityClear"
																:region-name="userInfo.region"
																:default-data="userInfo.city"
																:is-error="$v.userInfo.city.$dirty && !$v.userInfo.city.required"
																@selected="selectCity"
																@defaultRegion="chooseRegion"
															/>
														</label>
													</div>
												</div>
												<div
													v-if="!isRecruiter"
													class="cell full"
												>
													<div class="field-wrap">
														<label class="field-label">
															<textarea
																v-model="userInfo.about_me"
																rows="4"
																class="field"
																placeholder="О себе"
															/>
															<div class="label">О себе:</div>
														</label>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div
						v-if="!isRecruiter"
						class="profile-section"
					>
						<div class="block-title">
							Образование
						</div>
						<div
							v-if="!studyPlaces"
							class="preloader"
						/>
						<template v-else-if="renderStudyPlaces">
							<user-study-place
								v-for="(item, index) in studyPlaces"
								:key="index"
								class="need-el"
								:sp-data="item"
								@saved="saveEditStudyPlace($event, index)"
								@remove="removeStudyPlace(item.id, index)"
							/>
						</template>
						<transition-group
							name="list"
							tag="div"
						>
							<template v-if="renderNewSPs">
								<user-study-place
									v-for="(item, index) in newSPs"
									:key="index"
									:sp-data="item"
									@saved="saveEditNewSPs($event, index)"
									@remove="() => newSPs.splice(index, 1)"
								/>
							</template>
						</transition-group>

						<transition name="fade">
							<user-study-place
								v-show="isNewSPs"
								ref="new-sp"
								:is-add="isNewSPs"
								@added="addNewStudyPlace"
								@cancel="isNewSPs = false"
							/>
						</transition>

						<div
							v-if="!isNewSPs && !isWatch"
							class="field-wrap"
							@click="isNewSPs = true"
						>
							<button class="button-control">
								<img
									:src="'/img/icons/icon-add.png' | prodSrcImage"
									alt="Добавить образование"
								>
								Добавить образование
							</button>
						</div>
					</div>
					<div
						v-if="!isRecruiter"
						class="profile-section"
					>
						<div class="block-title">
							Опыт работы
						</div>
						<div
							v-if="!workPlaces"
							class="preloader"
						/>
						<template v-else>
							<user-work-place
								v-for="(item, index) in workPlaces"
								:key="item.id"
								:wp-data="item"
								@saved="saveEditWorkPlace($event, index)"
								@remove="removeWorkPlace(item.id, index)"
							/>
						</template>
						<transition-group
							name="list"
							tag="div"
						>
							<user-work-place
								v-for="(item, index) in newWPs"
								:key="index"
								:wp-data="item"
								@remove="() => newWPs.splice(index, 1)"
							/>
						</transition-group>

						<transition name="fade">
							<user-work-place
								v-show="isNewWPs"
								ref="new-wp"
								:is-add="isNewWPs"
								@added="addNewWorkPlace"
								@cancel="isNewWPs = false"
							/>
						</transition>

						<div
							v-if="!isNewWPs && !isWatch"
							class="field-wrap"
							@click="isNewWPs = true"
						>
							<button class="button-control">
								<img
									:src="'/img/icons/icon-add.png' | prodSrcImage"
									alt="Добавить место работы"
								>
								Добавить место работы
							</button>
						</div>
					</div>
					<div
						v-if="!isRecruiter"
						class="profile-section"
					>
						<h3 class="block-title">
							Предпочитаемые типы занятости
						</h3>
						<div class="checkbox__list checkbox__list--inline">
							<div
								v-for="(item, key) in userEmployments"
								:key="key"
								class="checkbox__point"
							>
								<label
									class="checkbox-filter"
									:for="`emp-${key}`"
								>
									<input
										:id="`emp-${key}`"
										v-model="userInfo.employment_type"
										type="checkbox"
										:value="item.slug"
									>
									<span>{{ item.value }}</span>
								</label>
							</div>
						</div>
					</div>
					<div class="profile-buttons">
						<div class="grid">
							<div class="box">
								<button
									v-if="!isSaving"
									class="button orange lg"
									@click.stop.prevent="saveChanges"
								>
									Сохранить изменения
								</button>
								<div
									v-else
									class="button orange create-resume-save lg button-preloader"
								>
									Сохранить изменения
									<div class="button-preloader-icons">
										<div class="button-preloader-icon" />
										<div class="button-preloader-icon" />
										<div class="button-preloader-icon" />
									</div>
								</div>
							</div>
							<div
								v-if="!isRecruiter"
								class="box"
							>
								<button
									class="button-control"
									@click="downloadProfile(userInfo.id)"
								>
									<a
										ref="link"
										style="display: none;"
									/>
									<div
										v-if="isDownloaded || !isLoaded"
										class="preloader xs"
									/>
									<div v-else>
										<img
											:src="'/img/icons/icon-pdf-2.svg' | prodSrcImage"
											alt="Скачать профиль"
										>
										<span>Скачать профиль</span>
									</div>
								</button>
							</div>
						</div>
					</div>
				</div>
				<div
					v-if="!isRecruiter"
					class="profile-block-footer"
				>
					<div class="grid">
						<div
							v-if="!careerUrl"
							class="box"
						>
							<h3 class="title">
								Пройдите профориентационный тест
							</h3>
							<router-link
								to="/career"
								class="button blue"
							>
								Пройти
							</router-link>
						</div>
						<div
							v-else
							class="box"
						>
							<h3 class="title">
								Результаты профориентационного теста
							</h3>
							<a
								href="#"
								class="button blue"
								@click.stop.prevent="openResults(careerUrl)"
							>Скачать</a>
						</div>
						<div class="box">
							<a
								href="#"
								class="link-dashed"
								@click="modalOpen('delete')"
							>Удалить аккаунт</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import {mapGetters, mapActions, mapState} from 'vuex'
import {required, email, maxLength} from 'vuelidate/lib/validators'
import {Phone, Birthday, alphaSpace} from '@/assets/scripts/custom_validators'
import UserWorkPlace from './user/UserWorkPlace';
import UserStudyPlace from './user/UserStudyPlace';
import User from '@/api/user'
import UserJob from '@/api/userJob'
import UserEd from '@/api/userEducation'

const MONTHS = [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь'
]

export default {
    components: {
        'user-work-place': UserWorkPlace,
        'user-study-place': UserStudyPlace
    },
    filters: {
        WPDateForInnerFormat(val, slug = null) {
            if (slug === 'Y') {
                return parseInt(val.split('-')[0])
            } else if (slug === 'M') {
                return parseInt(val.split('-')[1]) - 1
            } else {
                return `${parseInt(val.split('-')[1]) - 1}.${parseInt(val.split('-')[0])}`
            }
        }
    },
    data: () => ({
        isLoaded: false,
        isDownloaded: false,
        isSaving: false,
        idAvatar: null,
        isAvatarRemoved: false,
        userInfo: {
            id: '',
            first_name: '',
            last_name: '',
            middle_name: '',
            phone: '',
            city: '',
            email: '',
            birthday: '',
            region: '',
            about_me: '',
            employment_type: []
        },
        avatar: {
            dft: '/img/avatar.png',
            crnt: '',
            slct: ''
        },
        specialization: {
            preferred_position: '',
            salary: '',
            type_of_employment: ''
        },
        isEducationError: false,
        isCityError: false,
        isCityClear: false,
        workPlaces: undefined,
        studyPlaces: undefined,
        pullEditableWPs: [],
        pullRemovedWPs: [],
        isNewWPs: false,
        newWPs: [],
        isNewSPs: false,
        newSPs: [],
        pullEditableSPs: [],
        pullRemovedSPs: [],
        options: {
            years: new Array(),
            months: MONTHS
        },
        userPDF: null,
        renderStudyPlaces: true,
        renderNewSPs: true,
        chosenRegion: 0,
        chosenCity: 0
    }),
    validations() {
        if (this.userInfo.isVerification && !this.isRecruiter) {
            return {
                userInfo: {
                    first_name: {
                        required,
                        maxLength: maxLength(30),
                        alphaSpace
                    },
                    last_name: {
                        required,
                        alphaSpace
                    },
                    phone: {
                        required,
                        Phone
                    },
                    city: {
                        required,
                        alphaSpace
                    },
                    region: {
                        required,
                        alphaSpace
                    },
                    email: {
                        email,
                        required,
                        maxLength: maxLength(320)
                    },
                    birthday: {
                        required,
                        Birthday
                    }
                },
                specialization: {
                    preferred_position: {required}
                }
            }
        } else if (!this.userInfo.isVerification && !this.isRecruiter) {
            return {
                userInfo: {
                    first_name: {
                        required,
                        maxLength: maxLength(30),
                        alphaSpace
                    },
                    last_name: {
                        required,
                        alphaSpace
                    },
                    phone: {
                        required,
                        Phone
                    },
                    city: {
                        required,
                        alphaSpace
                    },
                    region: {
                        required,
                        alphaSpace
                    },
                    email: {
                        email,
                        required,
                        maxLength: maxLength(320)
                    },
                    birthday: {
                        required,
                        Birthday
                    }
                }
            }
        } else if (this.isRecruiter) {
            return {
                userInfo: {
                    first_name: {
                        required,
                        maxLength: maxLength(30),
                        alphaSpace
                    },
                    last_name: {
                        required,
                        alphaSpace
                    },
                    phone: {
                        required,
                        Phone
                    },
                    city: {
                        required,
                        alphaSpace
                    },
                    region: {
                        required,
                        alphaSpace
                    },
                    email: {
                        email,
                        required,
                        maxLength: maxLength(320)
                    },
                    birthday: {
                        required,
                        Birthday
                    }
                }
            }
        } else {
            return {
                userInfo: {
                    first_name: {
                        required,
                        maxLength: maxLength(30),
                        alphaSpace
                    },
                    last_name: {
                        required,
                        alphaSpace
                    },
                    phone: {
                        required,
                        Phone
                    },
                    city: {
                        required,
                        alphaSpace
                    },
                    region: {
                        required,
                        alphaSpace
                    },
                    email: {
                        email,
                        required,
                        maxLength: maxLength(320)
                    },
                    birthday: {
                        required,
                        Birthday
                    }
                },
                specialization: {
                    preferred_position: {required}
                }
            }
        }
    },
    computed: {
        ...mapGetters('cnst',{
            userEmployments: 'getUserEmployments'
        }),
        isWatch() {
            return this.$route.query.action === 'watch' ? true : false
        },
        srcAvatar() {
            if (this.avatar.slct) return this.avatar.slct
            else if (this.avatar.crnt) return this.avatar.crnt
            else return IS_DEV ? this.avatar.dft : `/staticfiles${this.avatar.dft}`
        },
        isRemoveAvatar() {
            return !!this.avatar.slct || !!this.avatar.crnt
        },
        selectedRegion() {
            return this.userInfo.region
        },
        isUserPhoneError() {
            if (this.userInfo.phone) return Phone(this.userInfo.phone)
            return true
        },
        ...mapGetters('user', {
            id: 'userID',
            user: 'userInfo',
            isVerification: 'UserIsVerification',
            isRecruiter: 'isRecruiter'
        }),
        ...mapState('user', [
            'user'
        ]),
        ...mapGetters('cnst', {
            employments: 'getEmployments'
        }),
        careerUrl() {
            return this.userInfo.career_guidance
        }
    },
    created() {
        this.getUserData()
        this.getStudyPlace().then(res => {
            this.studyPlaces = res
        })
        this.getWorkPlace().then(res => {
            this.workPlaces = res && res.length && res.map(x => ({
                ...x,
                commencement_date: this.$options.filters.WPDateForInnerFormat(x.commencement_date),
                completion_date: x.to_present ? null : this.$options.filters.WPDateForInnerFormat(x.completion_date)
            })) || []
        })
        let start = 1980;
        while (start <= new Date().getFullYear()) {
            this.options.years.push(start++)
        }
    },
    mounted() {
        this.$scrollTo(document.querySelector('header.header-role'))
    },
    methods: {
        ...mapActions('user', {
            getUserInfo: 'detail',
            save: 'change',
            download: 'downloadProfile',
            getStudyPlace: 'getStudyPlace',
            getWorkPlace: 'getWorkPlace'
        }),
        ...mapActions('cmn', {
            createImage: 'createImage',
            removeImage: 'removeImage',
            updateImage: 'updateImage'
        }),
        getUserData() {
            this.getUserInfo()
                .then(user => {
                    let [year, month, day] = user.birthday ? user.birthday.split('-') : ['', '', '']
                    this.userInfo.id = user.id
                    this.userInfo.first_name = user.first_name
                    this.userInfo.last_name = user.last_name
                    this.userInfo.middle_name = user.middle_name
                    this.userInfo.phone = user.phone
                    this.userInfo.region = user.region
                    this.userInfo.city = user.city ? user.city.name : ''
                    this.userInfo.email = user.email
                    this.userInfo.birthday = `${day}.${month}.${year}`
                    this.userInfo.career_guidance = user.career_guidance
                    this.userInfo.about_me = user.about_me
                    this.userInfo.employment_type = user.employment_type
                    this.avatar.crnt = user.image_user.length ? user.image_user[0].image : '';
                    this.idAvatar = user.image_user.length && user.image_user[0].id;
                    this.isLoaded = true
                })
        },
        selectCity(val) {
            if (val) this.isCityError = false
            if (!val) this.isCityError = true
            this.userInfo.city = val;
        },
        selectRegion(val) {
            this.userInfo.region = val;
        },
        chooseRegion(val) {
            if ( this.userInfo.region === '' ) {
                this.userInfo.region = val
                this.chosenRegion++
            }
        },
        chooseCity(val) {
            if ( this.userInfo.city !== '') {
                this.userInfo.city = val
                this.chosenCity++
            }
        },
        downloadProfile(id) {
            this.isDownloaded = true
            User.downloadProfile(id)
                .then(response => {
                    if (response.status === 204) this.downloadProfile(id)
                    else {
                        window.open(response.data.download_link, '_blank')
                        this.isDownloaded = false
                    }
                })
        },
        saveChanges() {
            this.$v.userInfo.$touch()
            if (!this.userInfo.city) this.isCityError = true
            else this.isCityError = false;
            if (this.$v.userInfo.$invalid) {
                this.$scrollTo(document.querySelector('header.header-role'))
            } else if ((!this.isCityError) || (!this.isCityError && !this.isEducationError)) {
                this.newWPs.forEach(place => {
                    let [month, year] = place.commencement_date.split('.')
                    if ( place.commencement_date != `${year}-${month}-01` ) {
                        month = +month + 1
                        place.commencement_date = `${year}-${month}-01`

                        if (place.to_present) {
                            place.completion_date = null
                        } else {
                            let [month, year] = place.completion_date.split('.')
                            month = +month + 1
                            place.completion_date = `${year}-${month}-01`
                        }
                    }
                })
                let [day, month, year] = this.userInfo.birthday && this.userInfo.birthday.split('.')
                let readyObj = {
                    ...this.userInfo,
                    city: {
                        name: this.userInfo.city
                    },
                    birthday: `${year}-${month}-${day}`
                }
                if (this.userInfo.phone && this.isUserPhoneError && this.user.phone !== this.userInfo.phone.replace(new RegExp('[\(\)\-]', 'g'), ''))
                    readyObj = {
                        ...readyObj,
                        phone: this.userInfo.phone
                    }
                else if (this.userInfo.phone && !this.isUserPhoneError) return
                else if (!this.userInfo.phone)
                    readyObj = {
                        ...readyObj,
                        phone: null
                    }
                this.isSaving = true
                delete readyObj.id
                if (this.newSPs.length > 0) {
                    this.newSPs.forEach( item => {
                        item.school_number = null
                        if (!item.city) item.city = null
                        if ((item.name === 'Неоконченное среднее' || item.name === 'Среднее') && item.number_course) item.number_course = null
                        if ((item.name !== 'Неоконченное среднее' || item.name !== 'Среднее') && item.school_class_number) item.school_class_number = null
                    })
                }
                if (localStorage.getItem('userRole') !== 'Schoolboy' && (this.studyPlaces.length > 0 || this.newSPs.length > 0)) {
                    let isError = false
                    if (this.newSPs.length === 0) {
                        let old = this.studyPlaces.filter(item => item.number_course === null)
                        if (old.length > 0) isError = true
                    }
                    else {
                        let newSPs = this.newSPs.filter(item => item.number_course === null)
                        if (newSPs.length > 0) isError = true
                    }
                    if (isError) {
                        this.isSaving = false;
                        this.modalOpen('studyFail', 'Укажите курс в образовании');
                        return;
                    }
                }
                if (localStorage.getItem('userRole') === 'Schoolboy' && (this.studyPlaces.length > 0 || this.newSPs.length > 0)) {
                    let isError = false
                    if (this.newSPs.length === 0) {
                        let old = this.studyPlaces.filter(item => item.school_class_number === null)
                        if (old.length > 0) isError = true
                    }
                    else {
                        let tmp = this.newSPs.filter(item => item.school_class_number === null)
                        if (tmp.length > 0) isError = true
                    }
                    if (isError) {
                        this.isSaving = false;
                        this.modalOpen('studyFail', 'Укажите класс в образовании');
                        return;
                    }
                }
                if (!this.isRecruiter && this.pullRemovedSPs.length > 0 && this.studyPlaces.length === 0 && this.newSPs.length === 0) {
                    this.modalOpen('studyFail', 'Укажите образование');
                    this.isSaving = false;
                } else if (!this.isRecruiter && this.studyPlaces.length === 0 && this.newSPs.length === 0) {
                    this.modalOpen('studyFail', 'Укажите образование');
                    this.isSaving = false;
                } else if (!this.isRecruiter && this.pullRemovedWPs.length > 0 && this.workPlaces.length === 0 && this.newWPs.length === 0 && (localStorage.getItem('userRole') === 'Teacher' || localStorage.getItem('userRole') === 'Specialist')) {
                    this.modalOpen('studyFail', 'Укажите место работы');
                    this.isSaving = false;
                } else if (!this.isRecruiter && this.workPlaces.length === 0 && this.newWPs.length === 0 && (localStorage.getItem('userRole') === 'Teacher' || localStorage.getItem('userRole') === 'Specialist')) {
                    this.modalOpen('studyFail', 'Укажите место работы');
                    this.isSaving = false;
                } else {
                    this.save(readyObj)
                        .then(() => UserEd.addStudyPlace({data: this.newSPs, id: this.userInfo.id}))
                        .then(() => UserJob.addWorkPlace({data: this.newWPs, id: this.userInfo.id}))
                        .then(() => {
                            this.pullEditableSPs.forEach(place => UserEd.changeStudyPlace({
                                user_id: this.userInfo.id,
                                place: {
                                    city: place.city,
                                    name: place.name,
                                    specialization: place.specialization,
                                    institution: place.institution,
                                    school_class_number: place.school_class_number,
                                    number_course: place.number_course,
                                    school_number: place.school_number,
                                    graduation_year: place.graduation_year,
                                    primary_education: place.primary_education
                                },
                                education_id: place.id
                            }))
                        })
                        .then(() => this.pullEditableWPs.forEach(place => {
                            let [year, month] = place.commencement_date.includes('.') ?
                                place.commencement_date.split('.').reverse() :
                                place.commencement_date.split('-')
                            place.commencement_date = `${year}-${place.commencement_date.includes('.') ? ++month : month}-01`

                            if (place.to_present) {
                                place.completion_date = null
                            } else {
                                let [year, month] = place.completion_date.includes('.') ?
                                    place.completion_date.split('.').reverse() :
                                    place.completion_date.split('-')
                                place.completion_date = `${year}-${place.completion_date.includes('.') ? ++month : month}-01`
                            }

                            UserJob.changeWorkPlace({
                                place,
                                user_id: this.userInfo.id,
                                job_id: place.id
                            })
                        }))
                        .then(() => {
                            this.pullRemovedWPs.forEach(item => UserJob.removeWorkPlace({
                                user_id: this.userInfo.id,
                                job_id: item
                            }))
                        })
                        .then(() => {
                            this.pullRemovedSPs.forEach(item => UserEd.removeStudyPlace({
                                user_id: this.userInfo.id,
                                education_id: item
                            }))
                        })
                        .then(() => {
                            if (this.isAvatarRemoved && this.idAvatar) {
                                this.removeImage(this.idAvatar).then(() => {
                                    this.idAvatar = null;
                                    this.isAvatarRemoved = false
                                }).then(() => this.getUserInfo())
                            }
                            if (this.avatar.slct && this.avatar.slct !== this.avatar.crnt) {
                                let formData = new FormData();
                                formData.append('image', this.$refs.avatar.files[0]);
                                formData.append('type', 'avatar');
                                formData.append('user', this.id);

                                this.idAvatar && this.updateImage({form: formData, id: this.idAvatar})
                                    .then(response => {
                                        this.modalOpen('userSuccess');
                                        this.idAvatar = response.id
                                        this.avatar.crnt = response.image
                                    })
                                    .then(() => this.getUserInfo())
                                !this.idAvatar && this.createImage(formData)
                                    .then(response => {
                                        this.modalOpen('userSuccess');
                                        this.idAvatar = response.id
                                        this.avatar.crnt = response.image
                                    })
                                    .then(() => this.getUserInfo())
                            } else {
                                this.modalOpen('userSuccess');
                            }
                        })
                        .catch(error => this.modalOpen('saveFail', error))
                        .finally(() => this.isSaving = false)
                }
            } else {
                console.log('Error in City or Education')
            }
        },
        changeAvatar() {
            let reader = new FileReader();
            let file = this.$refs.avatar.files[0]

            if (file) {
                if (file.size > Math.pow(2, 21)) {
                    this.modalOpen('stdFail', 'Превышен допустимый размер изображения')
                    return
                } else if (!/(jpeg)|(png)|(svg)/gi.test(file.type)) {
                    this.modalOpen('stdFail', 'Недопустимый формат изображения')
                    return
                } else {
                    reader.onload = event => {
                        this.avatar.slct = event.target.result
                    }
                    reader.readAsDataURL(file)
                }
            }
        },
        removeAvatar() {
            this.avatar.slct = null;
            this.avatar.crnt = null;
            this.$refs.avatar.value = null;
            this.isAvatarRemoved = true;
        },
        saveEditWorkPlace(data, index) {
            this.pullEditableWPs.push(data)
            this.workPlaces.splice(index, 1, data)
        },
        addNewWorkPlace(data) {
            this.isNewWPs = false
            this.newWPs.push(data)
        },
        removeWorkPlace(original, pseudo) {
            this.workPlaces.splice(pseudo, 1)
            this.pullRemovedWPs.push(original)
        },
        saveEditStudyPlace(data, index) {
            if (data.primary_education === true) {
                this.studyPlaces.forEach( item => {
                    if (item.primary_education) {
                        item.primary_education = !item.primary_education
                        this.pullEditableSPs.push(item)
                    }
                })
                this.newSPs.forEach( item => {
                    if (item.primary_education) item.primary_education = !item.primary_education
                })
            }
            this.pullEditableSPs.push(data)
            this.studyPlaces.splice(index,1, data)
            this.renderStudyPlaces = false;
            this.renderNewSPs = false;

            this.$nextTick(() => {
                // Add the component back in
                this.renderStudyPlaces = true;
                this.renderNewSPs = true;
            });
        },
        saveEditNewSPs(data, index) {
            if (data.primary_education === true) {
                this.studyPlaces.forEach( item => {
                    if (item.primary_education) {
                        item.primary_education = !item.primary_education
                        this.pullEditableSPs.push(item)
                    }
                })
                this.newSPs.forEach( item => {
                    if (item.primary_education) item.primary_education = !item.primary_education
                })
            }
            if (this.studyPlaces.length === 0 && this.newSPs.length === 0) data.primary_education = true
            this.isNewSPs = false
            this.newSPs.splice(index,1, data)
            this.renderStudyPlaces = false;
            this.renderNewSPs = false;

            this.$nextTick(() => {
                // Add the component back in
                this.renderStudyPlaces = true;
                this.renderNewSPs = true;
            });
        },
        addNewStudyPlace(data) {
            if (data.primary_education === true) {
                this.studyPlaces.forEach( item => {
                    if (item.primary_education) {
                        item.primary_education = !item.primary_education
                        this.pullEditableSPs.push(item)
                    }
                })
                this.newSPs.forEach( item => {
                    if (item.primary_education) item.primary_education = !item.primary_education
                })
            }
            if (this.studyPlaces.length === 0 && this.newSPs.length === 0) data.primary_education = true
            this.isNewSPs = false
            this.newSPs.push(data)
            this.renderStudyPlaces = false;
            this.renderNewSPs = false;

            this.$nextTick(() => {
                // Add the component back in
                this.renderStudyPlaces = true;
                this.renderNewSPs = true;
            });
        },
        removeStudyPlace(original, pseudo) {
            this.studyPlaces.splice(pseudo, 1)
            this.pullRemovedSPs.push(original)
        },
        openResults(careerUrl) {
            if(this.$metrika && (window.location.hostname === 'stage.lift.teachbasetest.ru' || window.location.hostname === 'lift-bf.ru')) {
                this.$metrika.reachGoal('proftestend')
            }
            this.modalOpen('payload', careerUrl)
        }
    }
}
</script>

<style lang="less">
.text-style {
    text-transform: capitalize;
}
.user-id {
    margin-bottom: 25px;
    padding-left: 15px;
    span {
        font-size: 12px;
        color: #999;
    }
}
@media (max-width: 719px) {
    .user-id {
        margin-bottom: 35px;
    }
}
</style>
