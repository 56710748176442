<template>
	<section class="section wow favourite">
		<div class="content">
			<div
				v-if="!favoriteVacancies.length"
				class="content-not-vacancy"
			>
				У вас нет избранных вакансий
			</div>
			<div
				v-else
				class="vacancy-card-wrap"
			>
				<div class="vacancy-card-group">
					<div
						v-for="(vacancy, index) in favoriteVacancies"
						:key="index"
						class="vacancy-card-item"
					>
						<div class="vacancy-card-inner">
							<div class="vacancy-tag-group">
								<div class="vacancy-tag-item">
									{{ typeEmploymentFormat(vacancy.type_employment) }}
								</div>
								<div class="vacancy-tag-item">
									{{ vacancy.city }}
								</div>
							</div>
							<div class="vacancy-card-title">
								<router-link
									:to="`/vacancies/${vacancy.id}`"
									class="vacancy-card-link"
								>
									<span>{{ vacancy.name }}</span>
								</router-link>
							</div>
							<h5 class="vacancy-card-subtitle">
								{{ vacancy.company.company_name }}
							</h5>
							<div
								class="text sm"
								v-text="$options.filters.dutiesFormat(vacancy.duties)"
							>
								<p>
									{{ vacancy.description }}
								</p>
							</div>
							<div class="button-group">
								<div
									class="button blue"
									@click.stop.prevent="
										eventYandexRespond(vacancy)
									"
								>
									Откликнуться
								</div>
								<div
									class="like-item active"
									@click="removeFavoriteVacancy(vacancy.id)"
								>
									<div class="like-icon" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    data() {
        return {
            vacancies: []
        };
    },
    computed: {
        ...mapGetters('user', ['favoriteVacancies']),
        ...mapGetters('cnst', {
            employments: 'getEmployments'
        }),
    },
    created() {
        this.$store.dispatch('user/getFavoriteVacancies').then(res => {
            this.vacancies = res;
        });
    },
    methods: {
        typeEmploymentFormat(type) {
            return this.employments.find(x => x.slug === type).value
        },
        async eventYandexRespond(vacancy) {
            if((window.location.hostname === 'stage.lift.teachbasetest.ru' || window.location.hostname === 'lift-bf.ru') && this.$metrika) {
                this.$metrika.reachGoal('click_response_vacancy', {
                    URL: document.location.href
                })
            }
            if (this.$store.getters['user/isLoggedIn']) {
                this.checker = vacancy.id;
                if (this.$store.state.user.responses) {
                    if (
                        this.checkLimitResponses(
                            this.$store.state.user.responses,
                            vacancy.id
                        )
                    )
                        this.modalOpen('reqVacancy', vacancy.id);
                    else
                        this.modalOpen(
                            'stdFail',
                            'Мы уже получили от тебя отклик на эту вакансию'
                        );
                    this.checker = undefined;
                } else {
                    this.$store
                        .dispatch('user/getAllResponses')
                        .then(result => {
                            if (this.checkLimitResponses(result, vacancy.id))
                                this.modalOpen('reqVacancy', vacancy.id);
                            else
                                this.modalOpen(
                                    'stdFail',
                                    'Мы уже получили от тебя отклик на эту вакансию'
                                );
                            this.checker = undefined;
                        });
                }
            } else {
                this.modalOpen('signIn');
            }
        },
        checkLimitResponses(responses, id) {
            // FIXME: заменить на значение из глобальных настроек сайта
            const limit = 1;
            const totalResponse = responses.reduce((accum, current) => {
                if (current.vacancy.id === id) return (accum += 1);
                else return accum;
            }, 0);

            return totalResponse < limit;
        },
        removeFavoriteVacancy(id) {
            this.modalOpen('deletedFavoriteVacancy', id);
        }
    }
};
</script>

<style lang="less">
.favourite {
    .vacancy-card-group{
        display: flex;
        flex-wrap: wrap;
        margin: 0 -25px;
        @media @desktop-end{
        }
        @media @laptop-end{
        }
        @media @tablet-end{
        }
        @media @mobile-end{
        }
    }

    .vacancy-card-item{
        position: relative;
        display: flex;
        width: 33.33%;
        padding: 0 25px 45px 25px;
        margin: 0;
        .transition;
        @media @tablet-end{
            width: 50%;
            margin-bottom: 0;
        }
        @media @mobile-end{
            width: 100%;
        }
        &:after{
            @media @mobile-end{
                opacity: 1 !important;
                content: '';
                position: absolute;
                bottom: -1px;
                left: -1000px;
                display: block;
                width: 3000px;
                height: 1px;
                background: #E6ECF4;
            }
        }
        &:nth-child(even){
            &:after{
                @media @desktop-end{
                }
                @media @laptop-end{
                }
                @media @tablet-end{
                    opacity: 1 !important;
                    content: '';
                    position: absolute;
                    bottom: -1px;
                    left: -1000px;
                    display: block;
                    width: 3000px;
                    height: 1px;
                    background: #E6ECF4;
                }
                @media @mobile-end{
                }
            }
            &:last-child{
                &:after{
                    @media @tablet-end{
                        opacity: 0;
                    }
                }
            }
        }
        &:nth-child(3n){
            &:after{
                content: '';
                position: absolute;
                bottom: -1px;
                left: -1000px;
                display: block;
                width: 3000px;
                height: 1px;
                background: #E6ECF4;
                @media @desktop-end{
                }
                @media @laptop-end{
                }
                @media @tablet-end{
                    opacity: 0;
                }
                @media @mobile-end{
                }
            }
            &:last-child{
                &:after{
                    opacity: 0;
                }
            }
        }
        &:last-child{
            &:after{
                @media @desktop-end{
                }
                @media @laptop-end{
                }
                @media @tablet-end{
                }
                @media @mobile-end{
                    display: none !important;
                }
            }
        }
        .cells{

        }
        .cell{
            &:nth-child(1){
                width: 66.66%;
                @media @desktop-end{
                }
                @media @laptop-end{
                }
                @media @tablet-end{
                }
                @media @mobile-end{
                    width: 100%;
                }
            }
            &:nth-child(2){
                width: 33.33%;
                text-align: right;
                @media @desktop-end{
                }
                @media @laptop-end{
                }
                @media @tablet-end{
                }
                @media @mobile-end{
                    width: 100%;
                    text-align: left;
                }
            }
        }
        .vacancy-tag-group{
            padding-bottom: 15px;
            @media @desktop-end{
            }
            @media @laptop-end{
            }
            @media @tablet-end{
                padding-bottom: 16px;
            }
            @media @mobile-end{
            }
        }
        .vacancy-tag-item{
            margin-bottom: 15px;
            @media @desktop-end{
            }
            @media @laptop-end{
            }
            @media @tablet-end{
            }
            @media @mobile-end{
            }
        }
        .text{
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            height: 66px;
            margin-bottom: 20px;
            @media @desktop-end{
            }
            @media @laptop-end{
            }
            @media @tablet-end{
            }
            @media @mobile-end{
                min-height: 0;
            }
        }
        .button-group{
            .button{
                margin-right: 25px;
                @media @desktop-end{
                }
                @media @laptop-end{
                }
                @media @tablet-end{
                }
                @media @mobile-end{
                }
                @media @oldy-end{
                    margin-right: 15px;
                }
            }
        }
    }
    .vacancy-card-title{
        min-height: 52px;
        margin-bottom: 15px;
        @media @desktop-end{
        }
        @media @laptop-end{
        }
        @media @tablet-end{
        }
        @media @mobile-end{
            min-height: 0;
        }
    }
    .vacancy-card-link{
        display: inline-block;
        .font-title;
        font-size: 18px;
        font-weight: 700;
        color: @color-text !important;
        &:hover{
            span{
                border-bottom-color: rgba(204, 204, 204, 0);
            }
        }
        span{
            border-bottom: 1px solid #ccc;
            .transition;
        }
    }
    .vacancy-card-subtitle{
        min-height: 42px;
        margin-bottom: 12px;
        font-size: 16px;
        font-weight: 400;
        @media @mobile-end{
            min-height: 0;
            margin-bottom: 15px;
        }
    }
    .vacancy-card-info{
        margin-bottom: 25px;
        font-size: 16px;
        color: @color-light;
    }
    .vacancy-card-salary{
        display: none !important;
        padding-top: 6px;
        font-size: 16px;
        @media @desktop-end{
        }
        @media @laptop-end{
        }
        @media @tablet-end{
        }
        @media @mobile-end{
            margin-bottom: 15px;
            padding-top: 0;
        }
    }
    .vacancy-card-city{
        margin-bottom: 15px;
        font-size: 16px;
        font-weight: 400;
        @media @mobile-end{
            margin-bottom: 15px;
        }
    }
    .vacancy-card-type{
        margin-bottom: 25px;
        font-size: 16px;
        font-weight: 400;
        color: @color-light;
        @media @mobile-end{
            margin-bottom: 20px;
        }
    }

    .vacancy-tag-group{
        margin-bottom: -10px;
        .vacancy-tag-item{
            margin-right: 10px;
            margin-bottom: 10px;
            &:last-child{
                margin-right: 0;
            }
        }
    }
    .vacancy-tag-item{
        display: inline-block;
        padding: 6px 17px;
        background: @color-light-blue;
        border-radius: 5px;
        font-size: 13px;
    }

    .like-item{
        display: inline-block;
        vertical-align: middle;
        cursor: pointer;
        &:hover{
            .like-icon{
                opacity: 1;
            }
            .like-title{
                color: @color-text;
            }
        }
        &.active{
            .like-icon{
                opacity: 1;
                background-image: url(../../../assets/img/icons/icon-favorite-active.svg);
            }
            .like-title{
            }
        }
    }
    .like-icon{
        opacity: 0.4;
        display: inline-block;
        vertical-align: middle;
        width: 18px;
        height: 16px;
        margin-right: 10px;
        background: url(../../../assets/img/icons/icon-favorite.svg) center center no-repeat;
        .transition;
        @media @oldy-end{
            margin-right: 5px;
        }
    }
    .like-title{
        //display: inline-block;
        display: none !important;
        vertical-align: middle;
        font-size: 16px;
        color: #999;
        .transition;
        @media @oldy-end{
            font-size: 15px;
        }
    }
}
</style>

